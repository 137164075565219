
.articles-content{
    display: flex;
    /*width: calc(100% - 280px);*/
    /*padding: 20px 20px 0 0;*/
    flex-direction: column;
    height: 100%;
    .articles-item{
        background: #fff;
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 20px 10px;
        .el-int{
            width: 250px;
            min-width: 250px;
            margin-left: 150px;
            position: relative;
            top: -5px;
        }
        .articles-name {
            line-height:30px;
        }
        
        
        .articles-list {
            display: flex;
            width: 1%;
            flex: 1;
        
            .item {
                line-height: 30px;
                padding: 0 10px;
                margin: 0 0 10px 10px;
                color: #666;
                cursor: pointer;
                &.current,&:hover{
                    background: #BEC3F1;
                    color: #2338E6;
                    border-radius: 4px;
                }
            }
        }
    }
    .articles-info{
        margin-right: -20px;
        display: flex;
        flex-wrap: wrap;
        .top-animate{
            width: calc(25% - 20px);
            margin: 20px 20px 0 0;
            border-radius: 8px;
            overflow: hidden;
            .articles-img{
                width: 100%;
                height: 0;
                padding-bottom: 57%;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                background: #fff;
                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            a{
                color: #333;
            }

            .info-title{
                background: #fff;
                padding-bottom: 16px;
                p{
                    padding:16px 16px 0;
                    margin: 0;
                }
                .text{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display:block;
                    -webkit-box-orient: vertical;
                    max-width: 370px;
                    max-height: 39px;
                    white-space: nowrap;
                }
            }
        }
    }
    .null-img{
        height:650px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
